<template>
    <div class="d-flex flex-column-fluid">
        <div class="container ">
            <div class="card card-custom gutter-b card-stretch">
                <div class="card-body pt-4">
                    <div class="d-flex justify-content-end">

                    </div>
                    <div class="d-flex align-items-center mb-7">

                    </div>
                    <p class="mb-7">
                        There are discrepancies regarding school term dates across Australian States and Territories.
                        Therefore, we ask that your weekly Kinnections conversations are structured using the terms and
                        weeks below as a guide for consistency and reporting purposes.
                    </p>
                    <table class="table text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Term 1 <br /> 23 January – 14 April</th>
                                <th scope="col">Term 2 <br /> 17 April – 7 July</th>
                                <th scope="col">Term 3 <br /> 10 July – 29 September</th>
                                <th scope="col">Term 4 <br /> 2 October – 21 December</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 1 </th>
                                <td>
                                    23 January – 27 January
                                </td>
                                <td>
                                    17 April – 21 April
                                </td>
                                <td>
                                    10 July – 14 July
                                </td>
                                <td>
                                    2 October – 6 October
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 2 </th>
                                <td>
                                    30 January – 3 February
                                </td>
                                <td>
                                    24 April – 28 April
                                </td>
                                <td>
                                    17 July – 21 July
                                </td>
                                <td>
                                    9 October – 13 October
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 3 </th>
                                <td>
                                    6 February – 10 February
                                </td>
                                <td>
                                    1 May – 5 May
                                </td>
                                <td>
                                    24 July – 28 July
                                </td>
                                <td>
                                    16 October – 20 October
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 4 </th>
                                <td>
                                    13 February – 17 February
                                </td>
                                <td>
                                    8 May – 12 May
                                </td>
                                <td>
                                    31 July – 4 August
                                </td>
                                <td>
                                    23 October – 27 October
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 5 </th>
                                <td>
                                    20 February – 24 February
                                </td>
                                <td>
                                    15 May – 19 May
                                </td>
                                <td>
                                    7 August – 11 August
                                </td>
                                <td>
                                    30 October – 3 November
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 6 </th>
                                <td>
                                    27 February – 3 March
                                </td>
                                <td>
                                    22 May – 26 May
                                </td>
                                <td>
                                    14 August – 18 August
                                </td>
                                <td>
                                    6 November – 10 November
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 7 </th>
                                <td>
                                    6 March – 10 March
                                </td>
                                <td>
                                    30 May – 2 June
                                </td>
                                <td>
                                    21 August – 25 August
                                </td>
                                <td>
                                    13 November – 17 November
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 8 </th>
                                <td>
                                    13 March – 17 March
                                </td>
                                <td>
                                    5 June – 9 June
                                </td>
                                <td>
                                    28 August – 1 September
                                </td>
                                <td>
                                    20 November – 24 November
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 9 </th>
                                <td>
                                    20 March – 24 March
                                </td>
                                <td>
                                    13 June – 16 June
                                </td>
                                <td>
                                    4 September – 8 September
                                </td>
                                <td>
                                    27 November – 1 December
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 10 </th>
                                <td>
                                    27 March – 31 March
                                </td>
                                <td>
                                    19 June – 23 June
                                </td>
                                <td>
                                    11 September – 15 September
                                </td>
                                <td>
                                    4 December – 8 December
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 11 </th>
                                <td>
                                    3 April – 6 April
                                </td>
                                <td>
                                    28 June – 30 June
                                </td>
                                <td>
                                    18 September – 22 September
                                </td>
                                <td>
                                    11 December – 15 December
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" style="white-space: nowrap;"> Week 12 </th>
                                <td>
                                    11 April – 14 April
                                </td>
                                <td>
                                    3 July – 7 July
                                </td>
                                <td>
                                    25 September – 29 September
                                </td>
                                <td>
                                    18 December – 21 December
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
    setup() {
        return {
        }
    },
})
</script>
